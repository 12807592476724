<script setup lang="ts">
import { Editor } from '@toast-ui/editor';
import DOMPurify from 'dompurify';

import '@toast-ui/editor/dist/toastui-editor.css';
import TemplateBase from '../TemplateBase';

const props = defineProps({
  stepElement: {
    type: Object,
    required: false,
    default: () => {},
  },
  stepId: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue', 'onElementChanged']);
const editor = ref();
const filledText = toRef(props, 'stepElement');

onMounted(() => {
  const editorComponent = new Editor({
    usageStatistics: false,
    hideModeSwitch: true,
    toolbarItems: [
      ['bold', 'italic'],
      ['ul', 'ol'],
    ],
    el: editor.value,
    height: '300px',
    initialEditType: 'wysiwyg',
    initialValue: props.stepElement.text || '',
    previewStyle: 'vertical',
    customHTMLSanitizer: (html) => {
      return DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ['b', 'i', 'ul', 'ol', 'li'],
        FORBID_TAGS: ['a', 'script', 'table', 'tr', 'td', 'th'],
        FORBID_ATTR: ['href', 'src', 'style', 'class', 'colspan', 'rowspan'],
      });
    },
    events: {
      blur: () => {
        filledText.value.text = editorComponent.getMarkdown().trim();
        emit('onElementChanged', props.stepId);
        emit('update:modelValue', editorComponent.getMarkdown());
      },
    },
  });
});
</script>

<template>
  <TemplateBase
    :placeholder="__('Label text area')"
    :element="props.stepElement"
    :step-id="props.stepId"
  >
    <template #templateContent>
      <div ref="editor" />
    </template>
  </TemplateBase>
</template>
